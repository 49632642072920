<template>
  <div>
    <md-table v-model="list_data.mdData" md-card md-fixed-header
    md-sort="customer_name" md-sort-order="asc" >
      <md-table-toolbar>
        <h1 class="md-title">Lista rubros</h1>
        <md-menu md-size="big" md-direction="bottom-end">
          <md-button class="md-icon-button" @click="go_to('/')">
            <md-icon>keyboard_backspace</md-icon>
          </md-button>
        </md-menu>
        <md-menu md-size="big" md-direction="bottom-end">
          <md-button class="md-icon-button" @click="go_to('/form_rubro')">
            <md-icon>add</md-icon>
          </md-button>
        </md-menu>
        <md-menu md-size="auto">
          <md-button @click.prevent="showDialog=true">
            <md-icon>filter_list</md-icon>
          </md-button>
        </md-menu>
      </md-table-toolbar>
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell
          md-label="Rubro"
          md-sort-by="customer_name" >{{ item.name }}</md-table-cell>
        <md-table-cell
          md-label="Tipo"
          md-sort-by="">{{ item.tipo_rubro|status_to_text }}</md-table-cell>
        <md-table-cell md-label="Editar" >
          <md-button class="md-icon-button" @click="go_to(`/form_rubro/${item.id}`)">
            <md-icon class="md-size-1x" >edit</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
      <md-table-pagination
        :md-page-size="rowsPerPage"
        :md-page-options="[3, 5, 10, 15]"
        :md-update="list"
        :md-paginated-data.sync="list_data" />
    </md-table>
    <md-empty-state
      md-icon="free_breakfast" v-if="list_data.mdData.length === 0"
      md-label="Crear un rubro"
      md-description="Aún no hay rubros registrados, crea el primero.">
      <md-button @click="go_to('/form_rubro')"
      md-theme="blue-button" class="md-accent md-raised">Crear rubro</md-button>
    </md-empty-state>
    <md-progress-bar md-mode="indeterminate" v-if="sending_request" />
    <br>
    <br>
    <br>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Filtros</md-dialog-title>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-field >
              <label for="name_field">Campo</label>
              <md-select v-model="filters.name_field"
              :disabled="sending"
              >
                <md-option :value="item.value"
                v-for="(item, key) in choosen_field"
                :key="key">{{item.text}}</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field >
              <label for="name_field">Criterio</label>
              <md-select v-model="filters.criterio"
              :disabled="sending"
              >
                <md-option :value="item.value"
                v-for="(item, key) in choosen_criterio"
                :key="key">{{item.text}}</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field >
              <label for="term">Termino a buscar</label>
              <md-input
              v-model="filters.termino" type="text" :disabled="sending" />
            </md-field>
          </div>
        </div>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog = false">Cerrar</md-button>
        <md-button class="md-primary" @click="list(1, rowsPerPage) ">Filtrar</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
import moment from 'moment';
/* eslint-disable */
export default {
  name: 'Rubros',
  created(){
    let current_filters = this.get_data_storage('filters_courses')
    if(current_filters){
      this.filters = current_filters
    }
    this.list(1, this.rowsPerPage)
  },
  data() {
    return {
      filters: {},
      list_data: {
        mdCount: null,
        mdPage: null,
        mdData: []
      },
      sending: false,
      showDialog: false,
      show_list: true,
      rowsPerPage: 10,
      filters: {
        bimestre: '',
      },
      url: 'rubros/',
      sending_request: false,
      choosen_field: [
        {value:'customer__juridica__name', text: 'Cliente'},
        {value:'fecha', text: 'Fecha'},
        {value:'amount', text: 'Total'},
        {value:'type_document', text: 'Tipo docuemnto'},
        {value:'iva', text: 'Iva'},
      ],
      choosen_criterio: [
        {value:'=', text: 'Igual'},
        {value:'>', text: 'Mayor'},
        {value:'<', text: 'Menor'},
        {value:'!=', text: 'Diferente'},
      ]
    };
  },
  methods: {
    go_to(url) {
      this.$router.push(url);
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    remove_storage(key) {
      localStorage.removeItem([key]);
    },
    get_data_storage(name) {
      return JSON.parse(localStorage.getItem(name));
    },
    reset_filters: function () {
      this.filters = {}
      this.remove_storage('filters_courses')
      this.list()
    },
    list(page, pageSize, sort, sortOrder) {
      let payload = {};
      const self = this;
      payload.paginate = "True"
      payload.page = page
      payload.per_page = pageSize
      if (this.sending_request) return false
      this.sending_request = true

    if (this.filters.name_field && this.filters.criterio) {
      payload.name_field = this.filters.name_field
      payload.criterio = this.filters.criterio
      payload.termino = this.filters.termino
    }
      this.sendRequest_with_url(this.url, payload).then((data) => {
        self.rowsPerPage = 50
        self.list_data = {
          mdCount: data.data.count,
          mdPage: page,
          mdData: data.data.results
        }
        // self.list_data = data.data;
        this.sending_request = false
        // this.save_storage('filters_courses', this.filters,)
      }).catch((error) => {
        this.sending_request = false
        this.handlerError(error)
      });
      return null;
    },
    async sendRequest(payload) {
      const result = await this.$http.get(this.url,{params:  payload});
      return result;
    },
    async sendRequest_with_url(url, payload) {
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    handlerError: function (error) {
      if (typeof (error.response.data) === 'object') {
        const result = error.response.data
        Object.entries(result).forEach(
          ([key, value]) => this.$swal.fire('Error!', key + ': ' + value, 'error')
        )
        return false
      }
      this.$swal.fire('Error!', error.response.data, 'error')
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'COMPRA';
      return 'VENTA';
    },
    to_date(value) {
      if (!value ) return '-----'
      return moment(value, 'YYYY-MM-DD', 'es').format('ll')
    },
  },
};

</script>
<style lang="scss" scoped>
  .md-table + .md-table {
    margin-top: 16px
  }
  .avatar img {
    max-width: 30px;
  }
</style>
<style lang="css" scoped>
  .on-over {
    cursor: pointer;
  }
  .fa-refresh {
      -webkit-animation: infinite-spinning 5s normal linear infinite;
      animation: infinite-spinning 5s normal linear infinite;
  }
  .null{
    text-decoration: line-through;
  }
  .fade-enter-active {
    transition: all .5s ease;
  }
  .fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .fade-enter, .fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  @keyframes infinite-spinning {
    0% {
       -webkit-transform: rotate3d(0, 0, 1, 0deg);
       transform: rotate3d(0, 0, 1, 0deg);
     }
     25% {
       -webkit-transform: rotate3d(0, 0, 1, 90deg);
       transform: rotate3d(0, 0, 1, 90deg);
     }
     50% {
       -webkit-transform: rotate3d(0, 0, 1, 180deg);
       transform: rotate3d(0, 0, 1, 180deg);
     }
     75% {
       -webkit-transform: rotate3d(0, 0, 1, 270deg);
       transform: rotate3d(0, 0, 1, 270deg);
     }
     100% {
       -webkit-transform: rotate3d(0, 0, 1, 360deg);
       transform: rotate3d(0, 0, 1, 360deg);
     }
  }
  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-active {
    animation: bounce-in .5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
